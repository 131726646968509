import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HLSPlayer from "./components/HLSPlayer";
import Home from "./pages/Home";

import "./App.css";

function App() {
  const hlsUrl = "https://skykeeper.org/live/danny.m3u8";

  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/danny" element={<HLSPlayer src={hlsUrl} />} />
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;
