import React, { useState, useEffect } from "react";
import figlet from "figlet";
import font from "figlet/importable-fonts/Poison.js";

const Home = () => {
  const [figletText, setFigletText] = useState("");

  useEffect(() => {
    figlet.parseFont("Poison", font);

    figlet.text(
      "skykeeper.org",
      {
        font: "Poison",
      },
      (err, data) => {
        if (err) {
          console.error(err);
          return;
        }
        setFigletText(data);
      }
    );
  }, []);

  return (
    <div>
      <pre>{figletText}</pre>
    </div>
  );
};

export default Home;
